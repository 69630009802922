<template>
  <div>
    <h3>Data Quality Issues</h3>
    <p>Within this section, we will show you if you have any errors within your data that may need fixing to ensure data accuracy does not negatively affect your Sales and Marketing campaigns.</p>
    <p>In total there are {{ audit.auditIssues.reduce((sum, issue) => sum + issue.total, 0).toLocaleString() }} records with data quality issues broken down below:</p>
    <b-input type="radio-group" v-model="display" :options="displayOptions" display="name" class="mb-3" />
    <div v-if="display.name == 'Charts'" class="row mb-3">
      <div class="col-12 col-lg-6">
        <b-pie-chart :data="pieData" :options="pieOptions" />
      </div>  
      <div class="col-12 col-lg-6">
        <b-bar-chart :data="barData" :options="barOptions" />
      </div>
    </div>
    <table v-else class="table">
      <thead>
        <tr>
          <th>Data Quality Issue</th>
          <th>Volume - Incorrect Values</th>
          <th>Completion</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="row in audit.auditIssues">
          <th>{{ row.issue }}</th>
          <td>{{ row.total }}</td>
          <td>{{ row.percentage }}%</td>
        </tr>
      </tbody>
    </table>
    <p>*Duplicates are flagged by our matching algorithms, where the records are considered to be the same based on the incoming company name, address and telephone number.</p>
    <div class="row">
      <div class="col-12 col-lg-6">
        <table class="table">
          <thead>
            <tr>
              <th>Examples of Incorrect Phone Numbers</th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="audit.auditIncorrectTelephones.length > 0" v-for="incorrectTelephone in audit.auditIncorrectTelephones">
              <td>{{ incorrectTelephone.telephone }}</td>
            </tr>
            <tr v-else>
              <td class="text-muted">No Incorrect Phone Numbers</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="col-12 col-lg-6">
        <table class="table">
          <thead>
            <tr>
              <th>Examples of Incorrect Postcodes</th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="audit.auditIncorrectPostcodes.length > 0" v-for="incorrectPostcode in audit.auditIncorrectPostcodes">
              <td>{{ incorrectPostcode.postcode }}</td>
            </tr>
            <tr v-else>
              <td class="text-muted">No Incorrect Postcodes</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "DataQualityIssues",
  data() {
    return {
      displayOptions: [{ name: "Charts" }, { name: "Table" }],
      display: { name: "Charts" },
      pieOptions: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          tooltip: {
            callbacks: {
              label: c => this.pieLabel(c)
            }
          },
          legend: {
            position: 'right',
            labels: {
              generateLabels: c => this.generateLabels(c)
            }
          },
          title: {
            display: true,
            text: "Data Quality Issues"
          }
        }
      },
      barOptions: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          x: {
            title: {
              display: true,
              text: "Data Quality Issue"
            }
          },
          y: {
            title: {
              display: true,
              text: "Completion"
            },
            ticks: {
              callback: (v) => v + "%"
            }
          }
        },
        plugins: {
          tooltip: {
            callbacks: {
              label: c => this.barLabel(c)
            }
          },
          legend: {
            display: false
          },
          title: {
            display: true,
            text: "Data Completion"
          }
        } 
      }
    };
  },
  computed: {
    pieData() {
      return {
        labels: this.audit.auditIssues?.map(d => d.issue) ?? [],
        datasets: [
          { data: this.audit.auditIssues?.map(d => d.total) ?? [], backgroundColor: this.generateColors(this.audit.auditIssues.length) }
        ]        
      };
    },
    barData() {
      return {
        labels: this.audit.auditIssues?.map(d => d.issue) ?? [],
        datasets: [
          { data: this.audit.auditIssues?.map(d => d.percentage) ?? [], backgroundColor: this.generateColors(this.audit.auditIssues.length) }
        ]        
      };
    }
  },
  methods: {
    generateColors(length) {
      var colors = ['#1C198E', '#F3B819', '#53A3EF', '#774F34'];
      var result = [];

      for (var i = 0; i < length; i++) {
          var colorIndex = i % colors.length;
          result.push(colors[colorIndex]);
      }

      return result;
    },
    generateLabels(chart) {
      var data = chart.data;

      if (data.labels.length && data.datasets.length) {
        return data.labels.map(function(label, i) {
          var total = data.datasets.reduce(function(acc, dataset) {
            return acc + dataset.data[i];
          }, 0);
          return {
            text: [label, total.toLocaleString() + " (" + parseFloat((100 / data.datasets[0].data.reduce((acc, currentValue) => acc + currentValue, 0) * total).toFixed(2)) + "%)"],
            fillStyle: data.datasets[0].backgroundColor[i]
          };
        });
      }
      return [];
    },
    pieLabel(context) {
      var parsed = context.chart.config.type == "bar" ? context.parsed.y : context.parsed;
      parsed += ' (' + parseFloat(((parsed / context.dataset.data.reduce((a, b) => a + b, 0)) * 100).toFixed(2)) + '%)';
      return parsed;
    },
    barLabel(context) {
      var parsed = context.chart.config.type == "bar" ? context.parsed.y : context.parsed;
      parsed += "%";
      return parsed;
    }
  },
  props: {
    audit: Object
  }
}
</script>

<style scoped>
h3 {
  border-bottom: 2px solid #18ba9b;
  padding-bottom: 1rem;
  margin-bottom: 1rem;
}
</style>