<template>
  <div>
    <h3>Matching</h3>
    <p>Market Location has developed its own bespoke matching software with over 100 different match grates - these are broken down into 3 subsets, A - good quality matches, B - medium quality matches and C - low quality matches.</p>
    <p>These matching grades can be reviewed to create a bespoke set of matching rules for your dataset. We will provide you with a set of samples for each match grade and you can see whether you agree with the matches for each level.</p>
    <p>Depending on the quality and population of the key fields within your dataset the C level match grades could also hold some good matches.</p>
    <p>The purpose of your output will usually dictate which match grades you accept - if you are looking to enhance your database with key fields such as employees, turnover, no of sites or even new contacts then we would recommend taking our A and B matches. If you are going to use the output as a suppression file to make sure that you don't purchase any records that you may already have then we would recommend accepting all match grades.</p>
    <p>Within our database we maintain 'live' records - these are companies that we've managed to call at least once a year to check the details that we have. The above statistics are the number of your data records that match to our 'live' database by top level match grades.</p>
    <div class="row mb-3">
      <div class="col-12 col-lg-6">
        <b-pie-chart v-if="!liveRecordsData?.datasets[0]?.data.every(v => v == 0)" :data="liveRecordsData" :options="options('Record Quality')" />
        <span v-else class="text-muted">No Record Quality Data</span>
      </div>
      <div class="col-12 col-lg-6">
        <b-pie-chart v-if="!recordTypesData?.datasets[0]?.data.every(v => v == 0)" :data="recordTypesData" :options="options('Record Types')" />
        <span v-else class="text-muted">No Record Types Data</span>
      </div>
    </div>
    <p>Alongside our 'live' database we also maintain flags for records that have met with a number of triggers to indicate that they are a Possible Goneaway (PG) at this point the record will be flagged to go through to the Call Centre for another call. If this is unsuccessful, we will call another Company close to the record and ask them to confirm if the record is still trading. After further confirmation and/or triggers i.e. unsuccessful delivery of emails then the record is flagged as a Goneaway (GA) and removed from our 'live' database.</p>
    <p>If we have matched one of your records to a PG or GA flag then it is an indicator that this Company is no longer trading at this address.</p>
    <p>If we match one of your records to an Exclude Request flag then this means that we have been asked by the record to remove the Company from our database - this does not mean that you have to remove the record from your database but be aware that they may not be open to receiving marketing communications from you.</p>
  </div>
</template>

<script>
export default {
  name: "Matching",
  data() {
    return {
      liveRecordsData: {
        labels: ['Good Quality Matches', 'Medium Quality Matches', 'Low Quality Matches'],
        datasets: [
          { data: [this.audit.auditRecordGrade.good, this.audit.auditRecordGrade.medium, this.audit.auditRecordGrade.low], backgroundColor: ['#1C198E', '#F3B819', '#53A3EF'] }
        ]        
      },
      recordTypesData: {
        labels: ['Live', 'Goneaways', 'Possible Goneaways', 'Exclude Requests'],
        datasets: [
          { data: [this.audit.auditRecordStatus.live, this.audit.auditRecordStatus.goneaway, this.audit.auditRecordStatus.possibleGoneaway, this.audit.auditRecordStatus.excludeRequest], backgroundColor: ['#1C198E', '#F3B819', '#53A3EF', '#774F34'] }
        ]        
      }
    };
  },
  methods: {
    options(title) {
      return {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          tooltip: {
            callbacks: {
              label: c => this.label(c)
            }
          },
          legend: {
            position: 'right',
            labels: {
              generateLabels: c => this.generateLabels(c)
            }
          },
          title: {
            display: true,
            text: title
          }
        }
      } 
    },
    generateLabels(chart) {
      var data = chart.data;

      if (data.labels.length && data.datasets.length) {
        return data.labels.map(function(label, i) {
          var total = data.datasets.reduce(function(acc, dataset) {
            return acc + dataset.data[i];
          }, 0);
          return {
            text: [label, total.toLocaleString() + " (" + parseFloat((100 / data.datasets[0].data.reduce((acc, currentValue) => acc + currentValue, 0) * total).toFixed(2)) + "%)"],
            fillStyle: data.datasets[0].backgroundColor[i]
          };
        });
      }
      return [];
    },
    label(context) {
      var parsed = context.chart.config.type == "bar" ? context.parsed.y : context.parsed;
      parsed += ' (' + parseFloat(((parsed / context.dataset.data.reduce((a, b) => a + b, 0)) * 100).toFixed(2)) + '%)';
      return parsed;
    }
  },
  props: {
    audit: Object
  }
}
</script>

<style scoped>
h3 {
  border-bottom: 2px solid #18ba9b;
  padding-bottom: 1rem;
  margin-bottom: 1rem;
}
</style>