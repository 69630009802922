<template>
  <div>
    <h3>Key Data Elements</h3>
    <p>Everyone sets up their main database differently, you may choose to only allow one instance of a phone number to ensure that you can allocate to Field Sales Teams with no overlap.</p>
    <p>Or you may have the system set up to only allow 1 record per Company Name with a different module of the database containing the many sites that the Company may have.</p>
    <p>These tables show you if you have a phone number in more than once and if so how many times it appears. Also, whether you have multiple occurrences of a Company Name and again how many times they appear.</p>
    <table class="table">
      <thead>
        <tr>
          <th>Company Names</th>
          <th>Number of Occurrences</th>
        </tr>
      </thead>
      <tbody>
        <tr v-if="audit.auditCompanyNames.length > 0" v-for="row in audit.auditCompanyNames">
          <td>{{ row.businessName }}</td>
          <td>{{ row.occurrences }}</td>
        </tr>
        <tr v-else>
          <td colspan="2" class="text-center text-muted">No Company Names</td>
        </tr>
      </tbody>
    </table>
    <table class="table">
      <thead>
        <tr>
          <th>Phone Number</th>
          <th>Number of Occurrences</th>
        </tr>
      </thead>
      <tbody>
        <tr v-if="audit.auditTelephones.length > 0" v-for="row in audit.auditTelephones">
          <td>{{ row.telephone }}</td>
          <td>{{ row.occurrences }}</td>
        </tr>
        <tr v-else>
          <td colspan="2" class="text-center text-muted">No Phone Numbers</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: "KeyDataElements",
  props: {
    audit: Object
  }
}
</script>

<style scoped>
h3 {
  border-bottom: 2px solid #18ba9b;
  padding-bottom: 1rem;
  margin-bottom: 1rem;
}
</style>