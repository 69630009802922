<template>
  <div>
    <h3>Evaluation</h3>
    <p>This is the first step and involves an initial review of the data supplied, allowing Market Location to assess any additional processing activity that may be required to achieve the highest possible match rate.</p>
    <p>Company Name, Post Code and Telephone Number are essential elements in the matching process to ensure accurate matching of the data supplied against the Market Location UK Business Database. If these fields are not well populated or contain inaccurate values, then it will have a negative effect on the matching and will impact the overall results across the Audit.</p>
    <p>The table below will show you the incoming fields of your data that will be used for matching and how well populated they are:</p>
    <table class="table">
      <thead>
        <tr>
          <th>Field Header</th>
          <th>Total Values Populated</th>
          <th>Completion</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="row in audit.auditEvaluations">
          <th>{{ row.field }}</th>
          <td>{{ row.populated.toLocaleString() }}</td>
          <td>{{ row.completion }}%</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: "Evaluation",
  props: {
    audit: Object
  }
}
</script>

<style scoped>
h3 {
  border-bottom: 2px solid #18ba9b;
  padding-bottom: 1rem;
  margin-bottom: 1rem;
}
</style>