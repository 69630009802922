<template>
  <nav :class="$route.path == '/' ? 'navbar navbar-expand navbar-light shadow-sm mb-3' : 'navbar navbar-expand navbar-light'">
    <div class="container-fluid">
      <a class="navbar-brand" href="/">
        <img src="https://bizi.118group.co.uk/assets/img/logo/logo_bizi.png" height="50" />
      </a>
      <ul class="navbar-nav mb-0">
        <li v-if="$root.isDevelopment && $root.skipAuthentication" class="nav-item">
          <p class="nav-link active mb-0">Development Environment</p>
        </li>
        <li v-else-if="$root.authenticated" class="nav-item dropdown">
          <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown">
            <svg height="32" width="32" class="me-2" fill="#28a745">
              <use xlink:href="/bootstrap-icons.svg#person-circle"/>
            </svg>
            {{ $keycloak.idTokenParsed.preferred_username }}
          </a>
          <ul class="dropdown-menu dropdown-menu-end">
            <li><a class="dropdown-item" :href="changePasswordUrl">Change Password</a></li>
            <li><hr class="dropdown-divider"></li>
            <li><a class="dropdown-item" href="#" @click="$root.logOut">Log Out</a></li>
          </ul>
        </li>
      </ul>
    </div>
  </nav>
  <nav v-if="currentParent" class="navbar navbar-expand navbar-dark mb-3" :style="'background-color: ' + currentParent?.color">
    <h5 class="me-auto mb-0 ms-3 text-white">
      Bizi Analytics
    </h5>
    <nav class="navbar-nav me-3">
      <ol class="breadcrumb mt-2 mb-2">
        <li class="breadcrumb-item mb-0"><a href="/">Home</a></li>
        <li v-if="currentParent?.name != currentPage?.name" class="breadcrumb-item mb-0"><a :href="currentParent?.path">{{ currentParent?.name }}</a></li>
        <li class="breadcrumb-item mb-0 active">{{ currentPage?.name }}</li>
      </ol>   
    </nav>  
  </nav>
</template>
<script>
export default {
  name: 'BiziNavbar',
  computed: {
    changePasswordUrl() {
      return "https://auth.118group.co.uk/realms/master/protocol/openid-connect/auth?response_type=code&client_id=" + this.$root.keycloakClient + "&redirect_uri=" + window.location.href + "&kc_action=UPDATE_PASSWORD";
    },
    currentParent() {
      return this.$root.navigation.filter(n => n.children?.some(c => c.path == this.$route?.matched[1]?.path))[0];
    },
    currentPage() {
      return this.currentParent?.children?.filter(c => c.path == this.$route?.matched[1]?.path)[0];
    }
  }
}
</script>

<style scoped>
  .breadcrumb-item {
    color: #FFF;
  }
  .breadcrumb-item::before {
    color: #FFF;
  }
  .breadcrumb-item a {
    text-decoration: none;
    color: #FFF;
  }
</style>