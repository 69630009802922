<template>
  <div>
    <h3>Duplication</h3>
    <p>This is the part of the process that identifies records that are included multiple times within your data set. Flagging or removing these duplicate records from your data set will improve your internal processes and reduce wasted Marketing spend.</p>
    <p>When we return these records back to you they will be flagged with an M for the master record and D for any duplicated records. We also use dupe IDs to group together the records to make them easier for you to remove on bulk.</p>
    <p>A duplicate will be flagged if the following fields are considered to be the same:</p>
    <ul>
      <li>Company Name</li>
      <li>Address</li>
      <li>Telephone</li>
    </ul>
    <div class="row mb-3">
      <div class="col-12 col-xl-6 mx-auto">
        <b-pie-chart :data="pieData" :options="pieOptions" />
      </div>
    </div>
    <b-input type="radio-group" v-model="display" :options="displayOptions" display="name" class="mb-3" />
    <div v-if="display.name == 'Chart'" class="row">
      <div class="col-12 col-xl-6 mx-auto">
        <b-bar-chart :data="barData" :options="barOptions" />
      </div>
    </div>
    <table v-else class="table mb-0">
      <thead>
        <tr>
          <th>Number of Duplicated Records</th>
          <th>Number of Groups</th>
          <th>Percent of File</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="row in this.audit.auditDuplicateBands">
          <td>{{ row.band }}</td>
          <td>{{ row.total.toLocaleString() }}</td>
          <td>{{ row.percentOfFile }}%</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: "Duplication",
  data() {
    return {
      totalRecords: 8350,
      displayOptions: [{ name: "Chart" }, { name: "Table" }],
      display: { name: "Chart" },
      pieOptions: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          tooltip: {
            callbacks: {
              label: c => this.label(c)
            }
          },
          legend: {
            position: 'right',
            labels: {
              generateLabels: c => this.generateLabels(c)
            }
          },
          title: {
            display: true,
            text: "Duplicate Records"
          }
        } 
      },
      barOptions: {
        responsive: true,
        maintainAspectRatio: true,
        scales: {
          x: {
            title: {
              display: true,
              text: "Number of Duplicated Records"
            }
          },
          y: {
            title: {
              display: true,
              text: "Percent of File"
            },
            ticks: {
              callback: (v) => v + "%"
            }
          }
        },
        plugins: {
          tooltip: {
            callbacks: {
              label: c => this.barLabel(c)
            }
          },
          legend: {
            display: false
          },
          title: {
            display: true,
            text: "Duplicate Records"
          }
        } 
      }
    };
  },
  computed: {
    pieData() {
      return {
        labels: ['Unique Records', 'Duplicate Records'],
        datasets: [
          { data: [this.audit.auditDuplicate.unique, this.audit.auditDuplicate.duplicate], backgroundColor: ['#1C198E', '#F3B819'] }
        ]        
      };
    },
    barData() {
      return {
        labels: this.audit.auditDuplicateBands.map(d => d.band),
        datasets: [
          { data: this.audit.auditDuplicateBands.map(d => d.percentOfFile), backgroundColor: this.generateColors(this.audit.auditDuplicateBands.length) }
        ]
      };
    }
  },
  methods: {
    generateColors(length) {
      var colors = ['#1C198E', '#F3B819', '#53A3EF', '#774F34'];
      var result = [];

      for (var i = 0; i < length; i++) {
          var colorIndex = i % colors.length;
          result.push(colors[colorIndex]);
      }

      return result;
    },
    generateLabels(chart) {
      var data = chart.data;

      if (data.labels.length && data.datasets.length) {
        return data.labels.map(function(label, i) {
          var total = data.datasets.reduce(function(acc, dataset) {
            return acc + dataset.data[i];
          }, 0);
          return {
            text: [label, total.toLocaleString() + " (" + parseFloat((100 / data.datasets[0].data.reduce((acc, currentValue) => acc + currentValue, 0) * total).toFixed(2)) + "%)"],
            fillStyle: data.datasets[0].backgroundColor[i]
          };
        });
      }
      return [];
    },
    label(context) {
      var parsed = context.chart.config.type == "bar" ? context.parsed.y : context.parsed;
      parsed += ' (' + parseFloat(((parsed / context.dataset.data.reduce((a, b) => a + b, 0)) * 100).toFixed(2)) + '%)';
      return parsed;
    },
    barLabel(context) {
      var parsed = context.chart.config.type == "bar" ? context.parsed.y : context.parsed;
      parsed += '% (' + this.audit.auditDuplicateBands.filter(adb => adb.band == context.label)[0].total + ")";
      return parsed;
    }
  },
  props: {
    audit: Object
  }
}
</script>

<style scoped>
h3 {
  border-bottom: 2px solid #18ba9b;
  padding-bottom: 1rem;
  margin-bottom: 1rem;
}
</style>