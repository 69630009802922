<template>
  <div>
    <h3>CTPS/TPS</h3>
    <p>The Telephone Preference Service (TPS) and Corporate Telephone Preference Service (CTPS) are opt out registers where individuals and businesses can register their wish not to receive unsolicited Sales and Marketing telephone calls.</p>
    <p>It is a legal requirement that companies do not make such calls to numbers registered on the TPS/CTPS. Administered by the DMA (Direct Marketing Association) consumers and businesses can subscribe should they wish to opt out of Sales and Marketing calls from any company.</p>
    <p>Businesses who make telemarketing calls must always consult the CTPS & TPS registers to ensure they are not contacting registered individuals or companies. If a registered individual or company is accidentally contacted, the company making the call is breaking the law and risks court action and a significant fine.</p>
    <p>We have matched all of the phone numbers within your data to the full TPS/CTPS lists and below are the match results:</p>
    <div class="row">
      <div class="col-12 col-xl-6 mx-auto">
        <b-pie-chart :data="data" :options="options" />
      </div>  
    </div>
  </div>
</template>

<script>
export default {
  name: "TPS",
  data() {
    return {
      data: {
        labels: ['Not on TPS/CTPS', 'On TPS/CTPS'],
        datasets: [
          { data: [this.audit.auditTps.notOnTps, this.audit.auditTps.onTps], backgroundColor: ['#1C198E', '#F3B819'] }
        ]        
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          tooltip: {
            callbacks: {
              label: c => this.label(c)
            }
          },
          legend: {
            position: 'right',
            labels: {
              generateLabels: c => this.generateLabels(c)
            }
          },
          title: {
            display: true,
            text: "TPS / CTPS"
          }
        } 
      }
    };
  },
  methods: {
    generateLabels(chart) {
      var data = chart.data;

      if (data.labels.length && data.datasets.length) {
        return data.labels.map(function(label, i) {
          var total = data.datasets.reduce(function(acc, dataset) {
            return acc + dataset.data[i];
          }, 0);
          return {
            text: [label, total.toLocaleString() + " (" + parseFloat((100 / data.datasets[0].data.reduce((acc, currentValue) => acc + currentValue, 0) * total).toFixed(2)) + "%)"],
            fillStyle: data.datasets[0].backgroundColor[i]
          };
        });
      }
      return [];
    },
    label(context) {
      var parsed = context.chart.config.type == "bar" ? context.parsed.y : context.parsed;
      parsed += ' (' + parseFloat(((parsed / context.dataset.data.reduce((a, b) => a + b, 0)) * 100).toFixed(2)) + '%)';
      return parsed;
    }
  },
  props: {
    audit: Object
  }
}
</script>

<style scoped>
h3 {
  border-bottom: 2px solid #18ba9b;
  padding-bottom: 1rem;
  margin-bottom: 1rem;
}
</style>