<template>
  <div>
    <h3>Suppression File</h3>
    <p>We have produced a suppression file which contain all 118IDs for any matches in your file. You can download the file directly or you can import the selection into Bizi Count to supress against your counts. Once you import you will see the file under (Supressions / Matching Jobs).</p>
    <b-button class="analytics me-1" @click="downloadSuppressionFile">
      <b-icon size="16" icon="file-earmark-spreadsheet" fill="transparent" class="btn-icon" />
      Download Suppression File (.drc)
    </b-button>
    <b-button class="data" @click="importIntoCountTool">
      <b-icon size="16" icon="graph-up-arrow" fill="transparent" class="btn-icon" />
      Import into Count Tool
    </b-button>
  </div>
</template>

<script>
export default {
  name: "SuppressionFile",
  methods: {
    downloadSuppressionFile() {
      window.open("https://bizi.118group.co.uk/Match/DownloadSupressionFile/" + this.audit.quantumAudit.matchJobId, 'bizineo-tab').focus();
    },
    importIntoCountTool() {
      window.open("https://bizi.118group.co.uk/Count/ImportMatchIntoCountPlatform/" + this.audit.quantumAudit.matchJobId, 'bizineo-tab').focus();
    }
  },
  props: {
    audit: Object
  }
}
</script>

<style scoped>
h3 {
  border-bottom: 2px solid #18ba9b;
  padding-bottom: 1rem;
  margin-bottom: 1rem;
}
</style>