<template>
  <div>
    <h3>GDPR</h3>
    <p>To comply with General Data Protection Regulation (GDPR) every business needs to review the way they gather, store and use personal data - and that includes employee, prospect and customer data. A main part of complying with GDPR is ensuring that any data you hold is accurate and up-to-date.</p>
    <p>The contact data you supplied has been reviewed and the table below shows the results. This table shows where we have found a match between your contact and what we hold on our database of verified contacts. It identifies where we don't have a match and so cannot validate the contact; and finally shows how many additional, verified contact details can be added to the business records you have supplied.</p>
    <table class="table">
      <thead>
        <tr>
          <th>Contacts</th>
          <th>Volume</th>
          <th>Percent</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="row in audit.auditVerifiedContacts">
          <th>{{ row.name }}</th>
          <td>{{ row.volume.toLocaleString() }}</td>
          <td>{{ row.percentage }}%</td>
        </tr>
      </tbody>
    </table>
    <table class="table">
      <thead>
        <tr>
          <th>Additional Contacts</th>
          <th>Volume</th>
          <th>Percent</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="row in audit.auditVerifiedAdditionalContacts">
          <th>{{ row.name }}</th>
          <td>{{ row.volume.toLocaleString() }}</td>
          <td>{{ row.percentage }}%</td>
        </tr>
      </tbody>
    </table>
    <p>On the calls that we make via the Call Centre we ask the Company who has decision making capacity at the site regardless of what job title they hold. We call these the Senior Decision Maker.</p>
    <p>We also place another call to source additional functional contacts - we refer to these as Contacts Plus. These cover the following roles:</p>
    <ul>
      <li>Finance Controller</li>
      <li>Buyer</li>
      <li>IT Contact</li>
      <li>HR</li>
      <li>Marketing Manager</li>
      <li>Telecommunications</li>
      <li>Facilities Manager</li>
      <li>Sales Manager</li>
      <li>Non-Executive Director</li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "GDPR",
  props: {
    audit: Object
  }
}
</script>

<style scoped>
h3 {
  border-bottom: 2px solid #18ba9b;
  padding-bottom: 1rem;
  margin-bottom: 1rem;
}
</style>