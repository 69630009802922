<template>
  <div>
    <h3>
      Net New Prospects
      <b-button class="analytics float-end" @click="viewInCountTool" :disabled="(this.audit?.quantumAudit?.analyticsStatus ?? 0) < 5">
        <b-icon size="16" icon="people-fill" fill="transparent" class="btn-icon" />
        View in Count Tool
      </b-button>
    </h3>
    <p>The process of enhancements not only gives you valuable insight into the quality and range of the data you hold, but it enables us at Market Location to add another level of sophistication by providing you with a view of records that you don't have but that fall into the category of look-a-like records.</p>
    <p>Look-a-like businesses will enable you to attract new audiences for your business because they have a similar profile to the customer information provided. In this way Market Location can identify opportunities for your business in the form of new prospect records.</p>
    <p>During this part of the process, we append our Market Sectors to your records and then use this to segment net new records that we can supply you for look-a-likes. Below we've grouped together the top 20 most common Market Sectors found within your dataset:</p>
    <p v-if="audit.auditMarketSectors.length == 0" class="text-muted">No Data</p>
    <div v-else>
      <b-input type="radio-group" v-model="marketSectorsDisplay" :options="displayOptions" display="name" class="mb-3" />
      <div v-if="marketSectorsDisplay.name == 'Chart'" class="mb-3" :style="'height:' + chartMarketSectors.length * 50 + 'px'">
        <b-bar-chart :data="marketSectorData" :options="options" />
      </div>
      <table v-else class="table">
        <thead>
          <tr>
            <th>Market Sector</th>
            <th>Total Incoming Records*</th>
            <th>Net New ML Records**</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="row in audit.auditMarketSectors">
            <td>{{ row.marketSector }}</td>
            <td>{{ row.totalIncoming.toLocaleString() }}</td>
            <td>{{ row.netNew.toLocaleString() }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <p>Below is the same breakdown but using SIC 03 instead of Market Sectors:</p>
    <p v-if="audit.auditSic03s.length == 0" class="text-muted">No Data</p>
    <div v-else>
      <b-input type="radio-group" v-model="sic03sDisplay" :options="displayOptions" display="name" class="mb-3" />
      <div v-if="sic03sDisplay.name == 'Chart'" class="mb-3" :style="'height:' + chartSic03.length * 50 + 'px'">
        <b-bar-chart :data="sic03Data" :options="options" />
      </div>
      <table v-else class="table">
        <thead>
          <tr>
            <th>SIC 03</th>
            <th>Total Incoming Records*</th>
            <th>Net New ML Records**</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="row in audit.auditSic03s">
            <td>{{ row.sic03 }}</td>
            <td>{{ row.totalIncoming.toLocaleString() }}</td>
            <td>{{ row.netNew.toLocaleString() }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div v-if="audit.auditMarketSectors.length != 0 && audit.auditSic03s.length != 0">
      <p class="text-muted">*number of client records that have been matched to our 'live' database within the Market Sector using A and B matches only.</p>
      <p class="text-muted mb-0">**minus any records within our 'live' database that have matched to a record that you've supplied - including all match grades.</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "NetNewProspects",
  data() {
    return {
      displayOptions: [{ name: "Chart" }, { name: "Table" }],
      marketSectorsDisplay: { name: "Chart" },
      sic03sDisplay: { name: "Chart" },
      options: {
        indexAxis: 'y',
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          y: {
            ticks: {
              callback: function(value, index, ticks) {
                return this.chart.data.labels[index];
                // TODO - trying to split string on multiple lines - look if there's another way to wrap
                // return this.chart.data.labels[index].match(/.{1,22}/g).map(str => str.endsWith(' ') ? str.trim() : str);
              }
            }
          },
          x: {
            max: 2500 // TODO - calculate best max
          }
        }
      }
    };
  },
  computed: {
    chartMarketSectors() {
      // TODO - sort this out
      return this.audit.auditMarketSectors.filter(ms => !ms.marketSector.startsWith("Total "));
    },
    marketSectorData() {
      return {
        labels: this.chartMarketSectors.map(ms => ms.marketSector),
        datasets: [
          { data: this.chartMarketSectors.map(ms => ms.totalIncoming), backgroundColor: ['#1C198E'], label: "Total Incoming Records*" },
          { data: this.chartMarketSectors.map(ms => ms.netNew), backgroundColor: ['#F3B819'], label: "Net New ML Records**" }
        ]
      };
    },
    chartSic03() {
      return this.audit.auditSic03s.filter(s => !s.sic03.startsWith("Total "));
    },
    sic03Data() {
      return {
        labels: this.chartSic03.map(s => s.sic03),
        datasets: [
          { data: this.chartSic03.map(s => s.totalIncoming), backgroundColor: ['#1C198E'], label: "Total Incoming Records*" },
          { data: this.chartSic03.map(s => s.netNew), backgroundColor: ['#F3B819'], label: "Net New ML Records**" }
        ]
      };
    }
  },
  methods: {
    viewInCountTool() {
      if ((this.audit?.quantumAudit?.analyticsStatus ?? 0) >= 5) {
        window.open("https://bizi.118group.co.uk/Count/GenerateProspectFile/" + this.audit.quantumAudit.matchJobId, 'bizineo-tab').focus();
      }
    }
  },
  props: {
    audit: Object
  }
}
</script>

<style scoped>
h3 {
  border-bottom: 2px solid #18ba9b;
  padding-bottom: 1rem;
  margin-bottom: 1rem;
}
</style>