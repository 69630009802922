<template>
  <div>
    <h3>Summary</h3>
    <p>Quality issues can result in marketing messages being sent to incorrect contacts - mailing to invalid addresses, phone calls to dead-lines or sending the same message more than once to the same business - all resulting in a waste of time and resources. Inaccurate and out of date data may, over time, see you liable under GDPR - so it will not only damage the reputation of your business, but it will almost certainly cost you money.</p>
    <p>Ensure the effort and expense that you invest in your company brand, promotional material and sales teams are supported by an up to date and reliable database. Market Location can help businesses of all sizes ensure that their databases are optimised to work at maximum effectiveness, by highlighting the errors, filling the gaps and identifying new opportunities - and most importantly, they are compliant with the rules set out by the GDPR.</p>
    <p>We hope that this audit report has helped you to understand your data better and provides you with information that you can use to put strategic plans in place to reach as much of your target audience as possible.</p>
    <p>Market Location can provide you with additional services to aid your campaigns further so if you are interested in any of the services listed below then please give us a call to find out more:</p>
    <ul>
      <li>Direct Mail campaigns</li>
      <li>Email Broadcasts (all set up, management and template building can be included if required). Long term nurture campaigns can also be created if required.</li>
      <li>Call Centre projects (minimum £10,000 spend on a 30-day campaign)</li>
      <li>API connections to ensure your data stays as up to date as ours.</li>
      <li>Data Management consulting to assist you with data flow between internal systems, regular deduping and/or cleaning projects. Advise on the best ways to get leads into your systems and out to your salesforce in an automated way.</li>
    </ul>
    <p>Contact us for more information using the details below:</p>
    <ul>
      <li>{{ audit.auditContact.name }}</li>
      <li>{{ audit.auditContact.telephone }}</li>
      <li>{{ audit.auditContact.email }}</li>
    </ul>
    <p>Market Location, 62 Anchorage Road, Sutton Coldfield, B74 2PG</p>
  </div>
</template>

<script>
export default {
  name: "Summary",
  props: {
    audit: Object
  }
}
</script>

<style scoped>
h3 {
  border-bottom: 2px solid #18ba9b;
  padding-bottom: 1rem;
  margin-bottom: 1rem;
}
</style>