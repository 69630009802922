<template>
  <div v-if="audit">
    <div :class="$root.viewAuditOnly ? 'container container-bs4 py-5 ps-4' : 'container p-5'">
      <h2 class="text-uppercase fw-bold">
        View Audit
        <span class="fs-6 analytics">#{{ audit.auditId }}</span>
      </h2>
      <p class="file">{{ audit.fileName }}</p>
      <p>Please navigate through the pages below to see details of your audit.</p>
      <b-alert v-if="audit.quantumAudit.analyticsStatus != 100" class="warning">Some background jobs are still running on your file, some features may be unavailable until this is complete</b-alert>
    </div>
    <div class="container-fluid audit">
      <div :class="$root.viewAuditOnly ? 'container container-bs4 py-3 ps-4' : 'container px-5 py-3'">
        <div class="row">
          <div class="col-12 d-block d-lg-none">
            <div class="input-group mb-3">
              <div class="input-group-text">
                <b-icon :icon="currentPage.icon" fill="#18ba9b" />
              </div>
              <b-select :options="pages.filter(p => p.enabled && p.id != 'DownloadAnalytics')" display="name" v-model="currentPage" hide-placeholder />
              <b-button class="analytics">
                <b-icon size="16" icon="cloud-arrow-down-fill" fill="transparent" class="btn-icon" />
                Download Analytics
              </b-button>
            </div>
          </div>
          <div class="d-none d-lg-block col-lg-3">
            <div v-for="page in pages" :class="'nav-item' + (currentPage == page ? ' active' : '') + (!page.enabled ? ' disabled' : '')" @click="selectPage(page)">
              <b-icon :icon="page.icon" :fill="!page.enabled ? '#6c757d' : '#18ba9b'" class=icon />
              <div class="label">{{ page.name }}<br class="d-xl-none" /><span v-if="page.comingSoon" class="badge bg-secondary ms-xl-2">Coming soon!</span></div>
            </div>
          </div>
          <div class="col-12 col-lg-9">
            <div class="page">
              <evaluation v-if="currentPage.id == 'Evaluation'" :audit="audit" />
              <data-quality-issues v-if="currentPage.id == 'DataQualityIssues'" :audit="audit" />
              <key-data-elements v-if="currentPage.id == 'KeyDataElements'" :audit="audit" />
              <matching v-if="currentPage.id == 'Matching'" :audit="audit" />
              <GDPR v-if="currentPage.id == 'GDPR'" :audit="audit" />
              <TPS v-if="currentPage.id == 'TPS'" :audit="audit" />
              <duplication v-if="currentPage.id == 'Duplication'" :audit="audit" />
              <enhance-your-data v-if="currentPage.id == 'EnhanceYourData'" :audit="audit" />
              <net-new-prospects v-if="currentPage.id == 'NetNewProspects'" :audit="audit" />
              <!-- <contacts v-if="currentPage.id == 'Contacts'" /> -->
              <Summary v-if="currentPage.id == 'Summary'" :audit="audit" />
              <suppression-file v-if="currentPage.id == 'SuppressionFile'" :audit="audit" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Evaluation from './Evaluation.vue';
import DataQualityIssues from './DataQualityIssues.vue';
import KeyDataElements from './KeyDataElements.vue';
import Matching from './Matching.vue';
import GDPR from './GDPR.vue';
import TPS from './TPS.vue';
import Duplication from './Duplication.vue';
import EnhanceYourData from './EnhanceYourData.vue';
import NetNewProspects from './NetNewProspects.vue';
// import Contacts from './Contacts.vue';
import Summary from './Summary.vue';
import SuppressionFile from './SuppressionFile.vue';

export default {
  name: "ViewAudit",
  friendlyName: "View Audit",
  components: {
    Evaluation,
    DataQualityIssues,
    KeyDataElements,
    Matching,
    GDPR,
    TPS,
    Duplication,
    EnhanceYourData,
    NetNewProspects,
    // Contacts,
    Summary,
    SuppressionFile
  },
  data() {
    return {
      pages: [{ id: "Evaluation", name: "Evaluation", icon: "search", enabled: true }, { id: "DataQualityIssues", name: "Data Quality Issues", icon: "exclamation-triangle-fill", enabled: true }, { id: "KeyDataElements", name: "Key Data Elements", icon: "key-fill", enabled: true }, { id: "Matching", name: "Matching", icon: "database-fill", enabled: true }, { id: "GDPR", name: "GDPR", icon: "patch-check-fill", enabled: true }, { id: "TPS", name: "CTPS/TPS", icon: "telephone-x-fill", enabled: true }, { id: "Duplication", name: "Duplication", icon: "copy", enabled: true }, { id: "EnhanceYourData", name: "Enhance Your Data", icon: "stars", enabled: true }, { id: "NetNewProspects", name: "Net New Prospects", icon: "person-plus-fill", enabled: true }, { id: "Contacts", name: "Contacts", icon: "people-fill", enabled: false, comingSoon: true }, { id: "Summary", name: "Summary", icon: "clipboard-fill", enabled: true }, { id: "SuppressionFile", name: "Suppression File", icon: "sign-stop-fill", enabled: false }, { id: "DownloadAnalytics", name: "Download Analytics", icon: "cloud-arrow-down-fill", enabled: false }],
      currentPage: null,
      audit: null,
      hashId: null
    };
  },
  computed: {
  },
  async created() {
    this.currentPage = this.pages[0];

    var searchParams = new URLSearchParams(window.location.search);
    this.hashId = this.$root.viewAuditOnly ? (searchParams.get("hashId") ?? searchParams.get("hashid")) : this.$route.params.id;

    await this.getAudit();
    await this.getQuantumAudit();
  },
  methods: {
    async getAudit() {
      if (this.hashId) {
        var response = await this.$root.get(this.$root.apiUrl + "/audits/" + this.hashId);

        this.audit = response.success ? response.data : null;
        this.enablePages();
      }
    },
    async getQuantumAudit() {
      if ((this.audit?.quantumAudit?.analyticsStatus ?? 0) < 100) {
        var response = await this.$root.get(this.$root.apiUrl + "/quantum-audits/" + this.hashId);

        this.audit.quantumAudit = response.success ? response.data : this.audit.quantumAudit;
        this.enablePages();

        setTimeout(() => this.getQuantumAudit(), 60000);
      }
    },
    enablePages() {
      if (this.audit && (this.audit?.quantumAudit?.analyticsStatus ?? 0) >= 3) {
        this.pages.filter(p => p.id == "DownloadAnalytics")[0].enabled = true;
      }

      if (this.audit && (this.audit?.quantumAudit?.analyticsStatus ?? 0) >= 6) {
        this.pages.filter(p => p.id == "SuppressionFile")[0].enabled = true;
      }
    },
    selectPage(page) {
      if (page.id == 'DownloadAnalytics' && (this.audit?.quantumAudit?.analyticsStatus ?? 0) >= 3) {
        window.open("https://bizi.118group.co.uk/Analytics/DownloadExcelReport/" + this.audit.quantumAudit.matchJobId, 'bizineo-tab').focus();
        return;
      }

      if (page.enabled) {
        this.currentPage = page;
      }
    }
  }
}
</script>

<style scoped>
.alert {
  border-radius: 0;
}

.input-group-text {
  border-radius: 0;
  background-color: white;
  border-right: none;
}

.form-select {
  border-left: none;
}

.file {
  text-transform: uppercase;
  border-bottom: 2px solid #18ba9b;
  padding-bottom: 1rem;
}

.audit {
  background-color: rgba(0,0,0,.03);
  padding: 3.5rem 0;

  .page {
    width: 100%;
    height: 100%;
    background-color: white;
    padding: 1rem;
  }

  .nav-item {
    background-color: white;
    padding: 1rem;
    margin-bottom: 1rem;
    width: 100%;
    cursor: pointer;

    @media (max-width: 1200px) {
      .icon {
        width: 100%;
        margin-top: -.25rem;
        margin-bottom: .75rem;
        margin-left: calc(50% - 16px);
      }

      .label {
        text-align: center;
        margin-bottom: -.25rem;
      }
    }

    @media (min-width: 1201px) {
      .icon {
        margin-top: -0.25rem;
        margin-right: .75rem;
        margin-left: -.25rem;
      }
    } 
  }

  .nav-item:last-child {
    margin-bottom: 0;
  }

  .nav-item:hover {
    outline: 1px solid #18ba9b;
  }

  .nav-item.active {
    outline: 1px solid #18ba9b;
    position:relative;
  }

  .nav-item.disabled {
    color: #6c757d;
    cursor: not-allowed;
  }

  .nav-item.disabled:hover {
    outline: none;
  }

  .nav-item.active::after {
    content: '';
    position: absolute;
    top: 50%;
    right: -0.5rem;
    border: solid transparent;
    border-width: 0.5rem 0 0.5rem 0.5rem;
    border-left-color: #18ba9b;
    transform: translateY(-50%);
    z-index: 0;
  }

  .nav-item.active::before {
    content: '';
    position: absolute;
    top: 50%;
    right: -0.5rem;
    border: solid transparent;
    border-width: 0.5rem 0 0.5rem 0.5rem;
    border-left-color: white;
    transform: translateY(-50%) translateX(-1px);
    z-index: 1;
  }
}

.container-bs4 {
  max-width: none !important;
}

@media (min-width: 576px) {
  .container-bs4 {
    max-width: 540px !important;
  }
}

@media (min-width: 768px) {
  .container-bs4 {
    max-width: 720px !important;
  }
}

@media (min-width: 992px) {
  .container-bs4 {
    max-width: 960px !important;
  }
}

@media (min-width: 1200px) {
  .container-bs4 {
    max-width: 1140px !important;
  }
}
</style>