<template>
  <div>
    <h3>Enhance Your Data</h3>
    <p>Segmentation and targeting are important to make sure that your Sales and Marketing campaigns are as successful as possible. You can gain a greater understanding of your customers and/or prospects by appending and enhancing additional characteristics and in turn increasing your revenue opportunities.</p>
    <p>The details below are based on A and B matches to our 'live' database:</p>
    <table class="table">
      <thead>
        <tr>
          <th>Enhancement</th>
          <th>Volume</th>
          <th>Percent</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="row in audit.auditEnhancements">
          <td>{{ row.enhancement }}</td>
          <td>{{ row.volume.toLocaleString() }}</td>
          <td>{{ row.percentage }}%</td>
        </tr>
      </tbody>
    </table>
    <p>*Personal email address - during the Call Centre call we ask for an email address that reaches the Senior Decision Maker. This can still be a mixture of named or generic email addresses.</p>
    <p>**Company email address - once we have a Personal email address, we then ask for a more generic email address that would reach a wider audience within the Company. This can still be a mixture of named or generic email addresses.</p>
  </div>
</template>

<script>
export default {
  name: "EnhanceYourData",
  props: {
    audit: Object
  }
}
</script>

<style scoped>
h3 {
  border-bottom: 2px solid #18ba9b;
  padding-bottom: 1rem;
  margin-bottom: 1rem;
}
</style>